import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 39px',
        '& .MuiTypography-root': {
            fontWeight: 600,
            textAlign: 'center',
        },
    },
    list1: {
        padding: 0,
        '& .MuiListItem-root': {
            padding: '16px 0',
            borderRadius: 4,
            '& svg': {
                width: 21,
                height: 21,
                marginRight: 8,
            },
            // '&:not(:last-child)': {
            //     borderBottom: 'solid 1px rgba(0,0,0,.05)',
            // },
        },
    },
    list2: {
        marginTop: 10,
        padding: '10px 0 0 0',
        textAlign: 'right',
        borderTop: 'solid 2px rgba(0,0,0,.03)',
        marginLeft: -16,
        marginRight: -16,
        '& .MuiListItem-root': {
            borderRadius: 4,
            fontSize: 10,
            padding: '8px 16px',
            // padding: 0,
            display: 'inline-flex',
            width: 'auto',
            '& .MuiTypography-root': {
                fontSize: theme.typography.body2.fontSize,
                color: theme.palette.text.disabled,
            },
            '& svg': {
                width: 21,
                height: 21,
                marginRight: 8,
            },
            // '&:first-child': {
            //     borderTop: 'solid 2px rgba(0,0,0,.03)',
            // },
            // '&:not(:last-child)': {
            //     marginRight: 14,
            // },
        },
    },
}));

export const GlobalHeaderMobileContent: FC<{
    close: () => void;
    setIsTosOpen: (isTosOpen: boolean) => void;
    setIsPpOpen: (isPpOpen: boolean) => void;
}> = ({ close, setIsTosOpen, setIsPpOpen }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            <MuiList className={c.list1}>
                <MuiListItem component={'a'} href={'/'} button onClick={() => close()}>
                    <MuiListItemText>{'moneybackとは'}</MuiListItemText>
                </MuiListItem>
                <MuiListItem component={'a'} href={'/guide'} button onClick={() => close()}>
                    <MuiListItemText>{'ご利用上の注意'}</MuiListItemText>
                </MuiListItem>
                <MuiListItem component={'a'} href={'/campaigns'} button onClick={() => close()}>
                    <MuiListItemText>{'公開キャンペーン一覧'}</MuiListItemText>
                </MuiListItem>
                <MuiListItem component={'a'} href={'/mypage'} button onClick={() => close()}>
                    <MuiListItemText>{'マイページ'}</MuiListItemText>
                </MuiListItem>
            </MuiList>
            <MuiList className={c.list2}>
                <MuiListItem
                    button
                    onClick={() => {
                        close();
                        setIsTosOpen(true);
                    }}
                >
                    <MuiListItemText>{'利用規約'}</MuiListItemText>
                </MuiListItem>
                <MuiListItem
                    button
                    onClick={() => {
                        close();
                        setIsPpOpen(true);
                    }}
                >
                    <MuiListItemText>{'プライバシーポリシー'}</MuiListItemText>
                </MuiListItem>
                <MuiListItem component={'a'} href={'https://media.moneyback.jp/'} button onClick={() => close()}>
                    <MuiListItemText>{'メディア様向け'}</MuiListItemText>
                </MuiListItem>
                <MuiListItem component={'a'} href={'https://corp.noshift.com/'} button onClick={() => close()}>
                    <MuiListItemText>{'運営者'}</MuiListItemText>
                </MuiListItem>
            </MuiList>
        </div>
    );
};
GlobalHeaderMobileContent.displayName = 'GlobalHeaderMobileContent';
