import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import MarkdownViewer from '@/components/0_atom/MarkdownViewer';

const useStyles = makeStyles((theme) => ({
    root: {},
    heading: {
        textAlign: 'center',
        marginBottom: 50,
    },
    tosText: {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: '1.6',
        '& h1': {
            // fontSize: theme.typography.h1.fontSize,
            // color: theme.palette.primary.main,
            // marginBottom: 34,
            // fontWeight: 600,
            display: 'none',
        },
        '& h2': {
            marginTop: '2em',
            fontSize: theme.typography.h2.fontSize,
            fontWeight: 600,
            // color: theme.palette.primary.main,
        },
        '& ol, ul': {
            paddingLeft: 15,
            '& li': {
                paddingLeft: 4,
                '&::marker': {
                    marginRight: 7,
                    fontWeight: 600,
                },
                '&:not(:last-child)': {
                    marginBottom: 7,
                },
                '& ol, ul': {
                    marginTop: 7,
                },
            },
        },
        '& a': {
            color: '#0a7',
            textDecoration: 'underline',
            wordBreak: 'break-all',
        },
    },
}));

export const LegalTextTemplate: FC<{
    className?: string;
    title: string | undefined;
    textMd: string | undefined;
}> = ({ className, title, textMd }) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA

    return (
        <section className={className}>
            {title && (
                <MuiTypography variant={'h1'} className={c.heading}>
                    {title}
                </MuiTypography>
            )}
            <section className={c.tosText}>
                {textMd ? <MarkdownViewer textMd={textMd} /> : <LoadingIndicator />}
            </section>
        </section>
    );
};
LegalTextTemplate.displayName = 'LegalTextTemplate';
