import { FC, useState, useMemo } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import MuiDrawer from '@material-ui/core/Drawer';
import MuiIconButton from '@material-ui/core/IconButton';
import SvgChevron from '@/svgs/fa-chevron-up-regular';
import { GlobalHeaderMobileContent } from '@/components/2_org/GlobalHeader/Mobile/Content';
import { TosModal } from '@/components/2_org/TosModal';
import { PpModal } from '@/components/2_org/PpModal';
import SvgBrand from '@/svgs/moneybackFulllogo';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '34px 10px 34px 10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: '50%',
        right: '50%',
        transform: 'translate(-50%, 0)',
        width: '100%',
        zIndex: 1300,
        background: '#fff',
        maxWidth: 700,
        transition: 'top .6s',
        borderRadius: '0 0 23px 23px',
    },
    button: {
        position: 'absolute',
        right: 10,
        top: 34 - 12,
        '& svg': {
            width: 14,
            height: 14,
            transform: 'rotateX(180deg)',
            transitionDuration: '.5s',
            transitionProperty: 'transform',
        },
    },
    svgOpen: {
        transform: 'rotateX(0deg) !important',
    },
    drawer: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        zIndex: '1299 !important' as any,
    },
    paper: {
        boxShadow: '0 0 64px 0 rgba(0,0,0,.2)',
        paddingTop: 83,
        paddingBottom: 22,
        borderRadius: '0 0 23px 23px',
        maxWidth: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    backdrop: {
        background: 'rgba(0,0,0,.1)',
    },
    brandA: {},
    brandSvg: {
        width: 'auto',
        height: 16,
    },
}));

export const GlobalHeaderMobile: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [isOpen, setIsOpen] = useState(false);
    const [isTosOpen, setIsTosOpen] = useState(false);
    const [isPpOpen, setIsPpOpen] = useState(false);
    const isScrolledUp = useScrollTrigger();

    // MEMO
    const content = useMemo(
        () => (
            <>
                <header
                    className={c.root}
                    style={{
                        top: isScrolledUp ? -90 : 0,
                    }}
                >
                    <a href={'/'} onClick={() => setIsOpen(false)} className={c.brandA}>
                        <SvgBrand className={c.brandSvg} />
                    </a>
                    <MuiIconButton onClick={() => setIsOpen(!isOpen)} className={c.button}>
                        <SvgChevron className={isOpen ? c.svgOpen : undefined} />
                    </MuiIconButton>
                </header>
                <MuiDrawer
                    anchor={'top'}
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    BackdropProps={{
                        classes: {
                            root: c.backdrop,
                        },
                    }}
                    classes={{
                        paper: c.paper,
                        root: c.drawer,
                    }}
                    transitionDuration={300}
                >
                    <GlobalHeaderMobileContent
                        close={() => setIsOpen(false)}
                        setIsTosOpen={setIsTosOpen}
                        setIsPpOpen={setIsPpOpen}
                    />
                </MuiDrawer>
                <TosModal isOpen={isTosOpen} setIsOpen={setIsTosOpen} />
                <PpModal isOpen={isPpOpen} setIsOpen={setIsPpOpen} />
            </>
        ),
        [isOpen, isScrolledUp, isTosOpen, isPpOpen]
    );

    return content;
};
GlobalHeaderMobile.displayName = 'GlobalHeaderMobile';
