import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiCircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    '@keyframes gradation': {
        '0%': {
            color: theme.palette.primary.main,
        },
        '100%': {
            color: theme.palette.secondary.main,
        },
    },
    root: {
        animation: '$gradation 0.7s linear infinite alternate',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '130px 0',
    },
    progress: {
        color: 'inherit',
    },
}));

export const LoadingIndicator: FC<{
    className?: string;
}> = ({ className }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={clsx(c.root, className)}>
            <MuiCircularProgress size={24} className={c.progress} />
        </div>
    );
};
LoadingIndicator.displayName = 'LoadingIndicator';
