import { FC, ReactElement } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { GlobalHeader } from '@/components/2_org/GlobalHeader';

const useStylesM = makeStyles((theme) => ({
    content: {
        marginTop: 0,
        paddingTop: 30 + 83,
        padding: 22,
    },
}));
const useStylesD = makeStyles((theme) => ({
    content: {
        marginTop: 0,
        paddingTop: 88 + 77,
        padding: 71,
        maxWidth: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

export const GlobalTemplate: FC<{
    children: ReactElement | ReactElement[];
    contentClassName?: string;
}> = ({ children, contentClassName }) => {
    // STYLE
    const t = useTheme();
    const m = useStylesM(t);
    const d = useStylesD(t);

    // HOOKS
    const isDesktop = useMediaQuery('(min-width:700px)');

    // DATA

    return (
        <>
            <GlobalHeader />
            <div className={clsx(isDesktop ? d.content : m.content, contentClassName)}>{children}</div>
        </>
    );
};
GlobalTemplate.displayName = 'GlobalTemplate';
