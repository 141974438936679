import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiDrawer from '@material-ui/core/Drawer';
import { usePrivacypolicy } from '@/utils/customHooks';
import { LegalTextTemplate } from '@/components/3_template/LegalTextTemplate';

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: '0 0 64px 0 rgba(0,0,0,.2)',
        padding: '49px 22px',
        borderRadius: '23px 23px 0 0',
        maxWidth: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '90%',
    },
    backdrop: {
        background: 'rgba(0,0,0,.1)',
    },
}));

export const PpModal: FC<{
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { privacypolicy } = usePrivacypolicy();

    return (
        <MuiDrawer
            anchor={'bottom'}
            open={isOpen}
            onClose={() => {
                setIsOpen(false);
            }}
            BackdropProps={{
                classes: {
                    root: c.backdrop,
                },
            }}
            classes={{
                paper: c.paper,
            }}
            transitionDuration={400}
        >
            <LegalTextTemplate title={'プライバシーポリシー'} textMd={privacypolicy} />
        </MuiDrawer>
    );
};
PpModal.displayName = 'PpModal';
